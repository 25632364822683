document.addEventListener('DOMContentLoaded', function () {
    var dateElements = document.querySelectorAll('.dateContainer');
    var tagElements = document.querySelectorAll('.card-tag');

    tagElements.forEach(function (tagElement) {
        var cardTag = tagElement.getAttribute('tag');

        if (cardTag === 'Standard') {
            tagElement.parentNode.classList.add('d-none');
        } else if (cardTag === 'Blog') {
            tagElement.style.backgroundColor = '#FE602F'; // eslint-disable-line
        } else if (cardTag === 'Research') {
            tagElement.style.backgroundColor = '#FFD600'; // eslint-disable-line
            tagElement.parentNode.style.paddingRight = '0'; // eslint-disable-line
        } else if (cardTag === 'Product') {
            tagElement.style.backgroundColor = '#3A1359'; // eslint-disable-line
            tagElement.style.color = '#FFFFFF'; // eslint-disable-line
            tagElement.parentNode.style.paddingRight = '0'; // eslint-disable-line
        } else if (cardTag === 'Opportunity') {
            tagElement.style.backgroundColor = '#FF8FE0'; // eslint-disable-line
        } else if (cardTag === 'Video') {
            tagElement.style.backgroundColor = '#23D23F'; // eslint-disable-line
        } else if (cardTag === 'Free Resources') {
            tagElement.style.backgroundColor = '#23D23F'; // eslint-disable-line
        }
    });

    dateElements.forEach(function (dateElement) {
        var cardDate = dateElement.getAttribute('date');

        if (cardDate === 'null') {
            dateElement.classList.add('d-none');
        } else if (cardDate) {
            var dateObj = new Date(cardDate);
            var isoString = dateObj.toISOString();

            // Get only date in YYYY-MM-DD format
            var datePart = isoString.split('T')[0];

            // Decompose the date into its parts
            var [year, month, day] = datePart.split('-'); // eslint-disable-line

            // Convert the month number to a short month name
            var monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
            var monthName = monthNames[parseInt(month, 10) - 1];

            var formattedDate = `${monthName} ${day}`;
            dateElement.innerHTML = formattedDate; // eslint-disable-line
        }
    });
});
